import Alpine from 'alpinejs';

Alpine.data('header', (homeUri) => ({
    mobileMenuOpen: false,

    isCurrentPage(path) {
        const currentPath = window.location.pathname;

        if (path === homeUri) {
            return currentPath === homeUri;
        }

        return currentPath.startsWith(path);
    }
}));